@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  text-decoration: none;
  border: 0;
}

body {
  font: 400 14px Roboto, sans-serif;
  background: #fff;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-font-smoothing: antialiased;
}

::-webkit-scrollbar {
  width:10px;
  height: 10px;
  }
   
  ::-webkit-scrollbar-track {
  background:rgba(0, 0, 0, 0.859);
  }
   
  ::-webkit-scrollbar-thumb {
  background: #3333338a;
  }